<template>
  <v-container>
    <collapser :initial-open="true">
      <template slot="title">Touren Publizieren</template>
      <template slot="subtitle"
        >Alle Touren in von einem Jahr publizieren.</template
      >
      <template slot="content"><event-publish-tool /></template>
    </collapser>
  </v-container>
</template>

<script>
import Collapser from '@/components/Collapser'
import EventPublishTool from '@/components/tools/EventPublishTool'

export default {
  name: 'AdminDashboard',
  components: { Collapser, EventPublishTool },
  props: {},
  data() {
    return {}
  },
  watch: {},
  mounted() { },
  methods: {},
}

</script>
